import { createStore } from 'vuex'

import app from './modules/app'
import user from './modules/user'

import getters from './getters'

//chuajianshili
const store = createStore({
  //modules
  modules: {
    app,
    user,
  },
  //getters
  getters,
})

//export
export default store
