export default defineNuxtPlugin(() => {})
// import * as Sentry from '@sentry/node'
// export default defineNuxtPlugin (nuxtApp => {
//   // Initialize Sentry
//   const app = nuxtApp.vueApp
//   Sentry.init({
//     dsn: 'https://66ac5f865e724a9a834f7bc536b63363@o4504580912119808.ingest.sentry.io/4504632174837760', // Use your Sentry DSN here
//     // Other Sentry options can be configured here
//   })

//   // Error handler
//   app.use(Sentry.Handlers.errorHandler())
// })
