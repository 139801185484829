import axios from 'axios'
import local from './local'
import store from '@/store'
import { Notify } from 'quasar'
import { Dialog } from 'quasar'
import { getAnalytics, logEvent } from 'firebase/analytics'
const instance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  timeout: 10000,
})

//request
instance.interceptors.request.use(
  config => {
    //jiang token daidao headers limian
    const token = local.get('token') || local.getLocal('token')
    // console.log(token)
    config.headers.authorization = 'Bearer ' + [token]
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// //response
instance.interceptors.response.use(
  response => {
    return response
  },
  err => {
    try {
      const { status, data } = err.response
      if (status == 452) {
        store.dispatch('app/A_error', data)
      } else if (status == 401) {
        Dialog.create({
          message: 'Please login to continue',
          cancel: true,
        })
          .onOk(() => {
            const analytics = getAnalytics()
            logEvent(analytics, 'STORE_WEB_LOGIN_CONTINUE', {
              cafe_id: local.get('cafe_id'),
            })
            window.location.href =
              window.location.href.split(':')[0] +
              '://' +
              window.location.host +
              `/transfer?redirect=${window.location.pathname}`
          }).onCancel(() => {
            const analytics = getAnalytics()
            logEvent(analytics, 'STORE_WEB_LOGIN_CANCEL', {
              cafe_id: local.get('cafe_id'),
            })
            local.remove('token')
            local.removeLocal('token')
            // console.log('Cancel')
          }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
          })
      } else if (status == 503) {
        Dialog.create({
          message: data.message,
          cancel: true,
        })
          .onOk(() => {
            // console.log('Ok')
          })
          .onCancel(() => {
            // console.log('Cancel')
          })
      }
    } catch (error) {
      // alert('抱歉，出错了')
    }

    return Promise.reject(err)
  }
)

//export instance
export default instance
