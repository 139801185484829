/* goodsInterface */
import request from '@/utils/request.js'

export const menusReq = params => {
  return request({
    method: 'GET',
    url: '/customerapi/V2/menus',
    params,
  })
}

// Get a list of favorite items
export const FavoritesReq = params => {
  return request({
    method: 'GET',
    url: '/customerapi/V1/favorites',
    params,
  })
}

export const AvailabilitiesReq = data => {
  return request({
    method: 'POST',
    url: '/customerapi/V1/productavailabilities',
    data,
  })
}

export const StripekeysReq = data => {
  return request({
    method: 'POST',
    url: '/customerapi/V2/onlineorders',
    data,
  })
}

export const PaymentmethodsReq = () => {
  return request({
    method: 'GET',
    url: '/customerapi/V2/stripe/paymentmethods',
  })
}

export const AttachmentReq = data => {
  return request({
    method: 'POST',
    url: '/customerapi/V2/stripe/paymentmethod/attachment',
    data,
  })
}

export const DetachmentReq = data => {
  return request({
    method: 'POST',
    url: '/customerapi/V2/stripe/paymentmethod/dettachment',
    data,
  })
}

export const PreferredReq = data => {
  return request({
    method: 'POST',
    url: '/customerapi/V2/stripe/paymentmethods/preferred',
    data,
  })
}

/** Confirm payment from client side **/
export const PaymentConfirmationReq = ({ id }) => {
  return request({
    method: 'POST',
    url: '/customerapi/V2/onlineorders/' + id + '/paymentconfirmation',
  })
}

export const OnlineordersReq = params => {
  return request({
    method: 'GET',
    url: '/customerapi/V1/onlineorders/' + params,
  })
}

export const OnlineorderslistReq = params => {
  return request({
    method: 'GET',
    url: 'customerapi/V1/onlineorders',
    params,
  })
}

export const CustomerpromotionslistReq = params => {
  return request({
    method: 'GET',
    url: '/customerapi/V1/customerpromotions',
    params,
  })
}

export const CustomerpromotionsReq = ({ id, cafe_id }) => {
  return request({
    method: 'GET',
    url: '/customerapi/V1/customerpromotions/' + id,
    params: { cafe_id: cafe_id },
  })
}

// Get Customer Credits
export const GetCreditsReq = () => {
  return request({
    method: 'GET',
    url: '/customerapi/V1/credits',
  })
}

/** Feedback for single online order **/
export const FeedbackReq = ({ id, data }) => {
  return request({
    method: 'POST',
    url: '/customerapi/V1/feedback/onlineorder/' + id,
    data,
  })
}

export const PickupTimeReq = () => {
  return request({
    method: 'GET',
    url: '/customerapi/V1/pickupslots',
  })
}

export const CafeqrReq = params => {
  return request({
    method: 'GET',
    url: '/customerapi/V1/cafeqr',
    params,
  })
}

export const CafeReq = params => {
  return request({
    method: 'GET',
    url: '/customerapi/V2/cafe',
    params,
  })
}

export const InvoiceReq = params => {
  return request({
    method: 'GET',
    url: '/customerapi/V1/onlineorders/' + params + '/invoice',
    responseType: 'blob',
  })
}
