import local from '@/utils/local'
const flavor = import.meta.env.VITE_ENV_FLAVOR
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (flavor == 'advanced') {
    if (to.path == '/') {
      return navigateTo('/nav/home')
    }
  } else if (flavor == 'qr') {
    if (to.path == '/') {
      return navigateTo('/preference')
    }
  }
  if (process.client) {
    let qr = local.get('QR')
    if (qr === null || qr === undefined) {
      if (to?.path.includes('nav')) {
        return true
      } else {
        if (to?.path != '/') {
          if (flavor == 'simple') {
            return navigateTo('/')
          } else if (flavor == 'qr') {
            if (to?.path == '/preference'){
              return true
            }else{
              return navigateTo('/preference')
            }
          }
        }
      }
    }
  }
})
