export default function (val) {
  // footer_dark
  let footer_dark_text_variant1 = val?.footer_dark.text.variant1
  let footer_dark_text_variant2 = val?.footer_dark.text.variant2
  let footer_dark_text_regular = val?.footer_dark.text.regular
  let footer_dark_bg1 = val?.footer_dark.bg[1]
  let footer_dark_outline_text_button = val?.footer_dark.outline.text_button

  // navigation_bar_home
  let navigation_bar_home_bg1 = val?.navigation_bar_home.bg[1]
  let navigation_bar_home_text_regular = val?.navigation_bar_home.text.regular
  let navigation_bar_home_text_selected = val?.navigation_bar_home.text.selected
  let navigation_bar_home_button_text_button =
    val?.navigation_bar_home.button.text_button
  let navigation_bar_home_button_bg = val?.navigation_bar_home.button.bg
  let navigation_bar_home_button_text_on =
    val?.navigation_bar_home.button.text_on

  // navigation_bar_order
  let navigation_bar_order_bg1 = val?.navigation_bar_order.bg[1]
  let navigation_bar_order_text_regular = val?.navigation_bar_order.text.regular
  let navigation_bar_order_button_text_button =
    val?.navigation_bar_order.button.text_button
  let navigation_bar_order_button_bg = val?.navigation_bar_order.button.bg
  let navigation_bar_order_button_text_on =
    val?.navigation_bar_order.button.text_on

  // footer_order
  let footer_order_text_variant1 = val?.footer_order.text.variant1
  let footer_order_text_regular = val?.footer_order.text.regular

  // navigation_bar_dropdown
  let navigation_bar_dropdown_bg_regular =
    val?.navigation_bar_dropdown.bg.regular
  let navigation_bar_dropdown_bg_selected =
    val?.navigation_bar_dropdown.bg.selected

  let navigation_bar_dropdown_text_regular =
    val?.navigation_bar_dropdown.text.regular
  let navigation_bar_dropdown_text_selected =
    val?.navigation_bar_dropdown.text.selected

  // Notify
  let Notify_bg_regular = val?.Notify.bg.regular
  let Notify_text_title_regular = val?.Notify.text.title.regular
  let Notify_text_content_regular = val?.Notify.text.content.regular
  let Notify_button_text_button = val?.Notify.button.text_button
  let Notify_Carousel_Selected = val?.Notify.Carousel.Selected
  let Notify_Carousel_regular = val?.Notify.Carousel.regular
  let Notify_outline_regular = val?.Notify.outline.regular

  // pag
  let pag_outline1 = val?.pag.outline[1]
  let pag_outline2 = val?.pag.outline[2]
  let pag_bg1 = val?.pag.bg[1]
  let pag_button_text_button_variant1 = val?.pag.button.text_button.variant1
  let pag_button_text_button_variant2 = val?.pag.button.text_button.variant2
  let pag_button_text_button_regular = val?.pag.button.text_button.regular
  let pag_button_bg1 = val?.pag.button.bg[1]
  let pag_button_bg2 = val?.pag.button.bg[2]
  let pag_button_text_on1 = val?.pag.button.text_on[1]
  let pag_button_text_on2 = val?.pag.button.text_on[2]
  let pag_text_title1 = val?.pag.text.title[1]
  let pag_text_title2 = val?.pag.text.title[2]
  let pag_text_content1 = val?.pag.text.content[1]
  let pag_text_content2 = val?.pag.text.content[2]
  let pag_text_selected = val?.pag.text.selected
  let pag_text_regular = val?.pag.text.regular
  let pag_text_variant1 = val?.pag.text.variant1
  let pag_icon_regular = val?.pag.icon.regular
  let pag_container_bg_variant1 = val?.pag.container.bg.variant1
  let pag_container_text_on_regular = val?.pag.container.text_on.regular

  // comment
  let comment_text_title_regular = val?.comment.text.title.regular
  let comment_text_title_variant1 = val?.comment.text.title.variant1
  let comment_text_content_regular = val?.comment.text.content.regular
  let comment_bg_regular = val?.comment.bg.regular
  let comment_icon1 = val?.comment.icon[1]

  // footer_dark
  document.documentElement.style.setProperty(
    '--footer_dark_text_variant1',
    footer_dark_text_variant1
  )
  document.documentElement.style.setProperty(
    '--footer_dark_text_variant2',
    footer_dark_text_variant2
  )
  document.documentElement.style.setProperty(
    '--footer_dark_text_regular',
    footer_dark_text_regular
  )
  document.documentElement.style.setProperty(
    '--footer_dark_bg1',
    footer_dark_bg1
  )
  document.documentElement.style.setProperty(
    '--footer_dark_outline_text_button',
    footer_dark_outline_text_button
  )

  // navigation_bar_home
  document.documentElement.style.setProperty(
    '--navigation_bar_home_bg1',
    navigation_bar_home_bg1
  )
  document.documentElement.style.setProperty(
    '--navigation_bar_home_text_regular',
    navigation_bar_home_text_regular
  )
  document.documentElement.style.setProperty(
    '--navigation_bar_home_text_selected',
    navigation_bar_home_text_selected
  )
  document.documentElement.style.setProperty(
    '--navigation_bar_home_button_text_button',
    navigation_bar_home_button_text_button
  )
  document.documentElement.style.setProperty(
    '--navigation_bar_home_button_bg',
    navigation_bar_home_button_bg
  )
  document.documentElement.style.setProperty(
    '--navigation_bar_home_button_text_on',
    navigation_bar_home_button_text_on
  )

  // navigation_bar_order
  document.documentElement.style.setProperty(
    '--navigation_bar_order_bg1',
    navigation_bar_order_bg1
  )
  document.documentElement.style.setProperty(
    '--navigation_bar_order_text_regular',
    navigation_bar_order_text_regular
  )
  document.documentElement.style.setProperty(
    '--navigation_bar_order_button_text_button',
    navigation_bar_order_button_text_button
  )
  document.documentElement.style.setProperty(
    '--navigation_bar_order_button_bg',
    navigation_bar_order_button_bg
  )
  document.documentElement.style.setProperty(
    '--navigation_bar_order_button_text_on',
    navigation_bar_order_button_text_on
  )

  // footer_order
  document.documentElement.style.setProperty(
    '--footer_order_text_variant1',
    footer_order_text_variant1
  )
  document.documentElement.style.setProperty(
    '--footer_order_text_regular',
    footer_order_text_regular
  )

  // navigation_bar_dropdown
  document.documentElement.style.setProperty(
    '--navigation_bar_dropdown_bg_regular',
    navigation_bar_dropdown_bg_regular
  )
  document.documentElement.style.setProperty(
    '--navigation_bar_dropdown_bg_selected',
    navigation_bar_dropdown_bg_selected
  )
  document.documentElement.style.setProperty(
    '--navigation_bar_dropdown_text_regular',
    navigation_bar_dropdown_text_regular
  )
  document.documentElement.style.setProperty(
    '--navigation_bar_dropdown_text_selected',
    navigation_bar_dropdown_text_selected
  )

  // Notify
  document.documentElement.style.setProperty(
    '--Notify_bg_regular',
    Notify_bg_regular
  )
  document.documentElement.style.setProperty(
    '--Notify_text_title_regular',
    Notify_text_title_regular
  )
  document.documentElement.style.setProperty(
    '--Notify_text_content_regular',
    Notify_text_content_regular
  )
  document.documentElement.style.setProperty(
    '--Notify_button_text_button',
    Notify_button_text_button
  )
  document.documentElement.style.setProperty(
    '--Notify_Carousel_Selected',
    Notify_Carousel_Selected
  )
  document.documentElement.style.setProperty(
    '--Notify_Carousel_regular',
    Notify_Carousel_regular
  )
  document.documentElement.style.setProperty(
    '--Notify_outline_regular',
    Notify_outline_regular
  )

  // pag
  document.documentElement.style.setProperty('--pag_outline1', pag_outline1)
  document.documentElement.style.setProperty('--pag_outline2', pag_outline2)
  document.documentElement.style.setProperty('--pag_bg1', pag_bg1)
  document.documentElement.style.setProperty(
    '--pag_button_text_button_variant1',
    pag_button_text_button_variant1
  )

  document.documentElement.style.setProperty(
    '--pag_button_text_button_variant2',
    pag_button_text_button_variant2
  )
  document.documentElement.style.setProperty(
    '--pag_button_text_button_regular',
    pag_button_text_button_regular
  )

  document.documentElement.style.setProperty('--pag_button_bg1', pag_button_bg1)
  document.documentElement.style.setProperty('--pag_button_bg2', pag_button_bg2)
  document.documentElement.style.setProperty(
    '--pag_button_text_on1',
    pag_button_text_on1
  )
  document.documentElement.style.setProperty(
    '--pag_button_text_on2',
    pag_button_text_on2
  )
  document.documentElement.style.setProperty(
    '--pag_text_title1',
    pag_text_title1
  )
  document.documentElement.style.setProperty(
    '--pag_text_title2',
    pag_text_title2
  )
  document.documentElement.style.setProperty(
    '--pag_text_content1',
    pag_text_content1
  )
  document.documentElement.style.setProperty(
    '--pag_text_content2',
    pag_text_content2
  )
  document.documentElement.style.setProperty(
    '--pag_text_selected',
    pag_text_selected
  )
  document.documentElement.style.setProperty(
    '--pag_text_regular',
    pag_text_regular
  )
  document.documentElement.style.setProperty(
    '--pag_text_variant1',
    pag_text_variant1
  )
  document.documentElement.style.setProperty(
    '--pag_icon_regular',
    pag_icon_regular
  )
  document.documentElement.style.setProperty(
    '--pag_container_bg_variant1',
    pag_container_bg_variant1
  )
  document.documentElement.style.setProperty(
    '--pag_container_text_on_regular',
    pag_container_text_on_regular
  )

  // comment
  document.documentElement.style.setProperty(
    '--comment_text_title_regular',
    comment_text_title_regular
  )
  document.documentElement.style.setProperty(
    '--comment_text_title_variant1',
    comment_text_title_variant1
  )
  document.documentElement.style.setProperty(
    '--comment_text_content_regular',
    comment_text_content_regular
  )
  document.documentElement.style.setProperty(
    '--comment_bg_regular',
    comment_bg_regular
  )
  document.documentElement.style.setProperty('--comment_icon1', comment_icon1)
}
