import local from '@/utils/local'
//state
const state = {
  token: local.get('token') || local.getLocal('token') || null,
  goodslist: [],
  book_menu: local.get('book_menu') || [],
  searchlist: [],
  details: [],
  shopcar: local.get('shopcar') || [],
  bookShopcar: local.get('bookShopcar') || [],
  bookshop: local.get('booking-shop') || {},
  orderdetails: [],
  choose: local.get('choose') || 'Take away',
  setdiv: '',
  isdisplay: false,
  // axios message
  axios_message: '',

  // delivery address
  delivery_address: local.get('delivery_address') || {},
  add_address: local.get('add_address') || {},
}

//mutations
const mutations = {
  INIT_TOKEN(state, data) {
    state.token = data
  },
  CLEAR_TOKEN(state) {
    state.token = null
    local.remove('token')
    local.removeLocal('token')
    local.remove('customer_id')
    local.removeLocal('customer_id')
  },
  INIT_GOODSLIST(state, data) {
    for (const obj of data) {
      for (const item of obj.items) {
        item.num = 1
      }
    }
    state.goodslist = data
    state.searchlist = data
    local.set('categories', data)
  },
  INIT_BOOK_MENU(state, data) {
    for (const obj of data) {
      for (const item of obj.items) {
        item.isSelected = false
        for (const label of item.industry_labels) {
          label.flag = false
        }
      }
    }
    state.book_menu = data
    local.set('book_menu', data)
  },
  GET_DETAILS(state, data) {
    data.num = 1
    for (const obj of data.options) {
      obj.option_id = obj.id
      obj.selected = false
      obj.max_variance = 1
      if (obj.max_variances) {
        obj.max_variance = obj.max_variances
      } else {
        if (obj.multi_selection) {
          obj.max_variance = obj.variances.length
        } else {
          obj.max_variance = 1
        }
      }
      for (const item of obj.variances) {
        if (obj.type == 1 && obj.variances.length == 1) {
          item.flag = true
        } else {
          item.flag = false
        }
      }
    }
    state.details = data
  },
  CHANGE_FLAG(state, data) {
    for (const obj of state.details.options) {
      if (obj.id == data[0]) {
        if (obj.max_variance > 1) {
          for (const item of obj.variances) {
            if (item.id == data[1]) {
              item.flag = !item.flag
              obj.selected = obj.variances.some(item => item.flag)
            }
          }
        } else {
          for (const item of obj.variances) {
            if (item.id == data[1]) {
              item.flag = !item.flag
              obj.selected = obj.variances.some(item => item.flag)
            } else {
              item.flag = false
            }
          }
        }
      }
    }
  },
  SHOPCAR(state, data) {
    state.shopcar.push(data)
    local.set('shopcar', state.shopcar)
  },
  BOOKSHOPCAR(state, data) {
    state.bookShopcar.push(data)
    local.set('bookShopcar', state.bookShopcar)
  },
  BOOKSHOP(state, data) {
    state.bookshop = data[0]
    if (data[1]) {
      local.set('booking-shop', state.bookshop)
    }
  },
  ADDSHOPS(state, data) {
    for (const [index, item] of state.shopcar.entries()) {
      if (index == data) item.amount++
    }
    local.set('shopcar', state.shopcar)
  },
  ADDBOOKSHOPS(state, data) {
    for (const [index, item] of state.bookShopcar.entries()) {
      if (index == data) item.amount++
    }
    local.set('bookShopcar', state.bookShopcar)
  },
  SUBSHOPS(state, data) {
    for (const [index, item] of state.shopcar.entries()) {
      if (index == data) item.amount--
    }
    local.set('shopcar', state.shopcar)
  },
  SUBBOOKSHOPS(state, data) {
    for (const [index, item] of state.bookShopcar.entries()) {
      if (index == data) item.amount--
    }
    local.set('bookShopcar', state.bookShopcar)
  },
  CLEAR_SHOPCAR(state) {
    state.shopcar = []
    local.remove('shopcar')
  },
  SPLICE_SHOPCAR(state, data) {
    state.shopcar.splice(data, 1)
    local.set('shopcar', state.shopcar)
  },
  SPLICE_BOOKSHOPCAR(state, data) {
    state.bookShopcar.splice(data, 1)
    local.set('bookShopcar', state.bookShopcar)
  },
  CHANGE_SHOPCAR(state, data) {
    state.shopcar = data
    local.set('shopcar', state.shopcar)
  },
  CHANGE_BOOKSHOPCAR(state, data) {
    state.bookShopcar = data
    local.set('bookShopcar', state.bookShopcar)
  },
  CHANGE_SHOP(state) {
    state.bookshop = []
  },
  ORDER_DETAILS(state, data) {
    state.orderdetails = data
  },
  CHOOSE(state, data) {
    state.choose = data.number
    local.set('choose', state.choose)
    if (data.id) {
      local.set('table', data.id)
    } else {
      local.remove('table')
    }
  },
  CHOOSE1(state, data) {
    state.choose = data
    local.set('choose', state.choose)
  },
  SETDIV(state, data) {
    state.setdiv = data
  },
  SEARCH(state, data) {
    state.searchlist = JSON.parse(JSON.stringify(state.goodslist))
    if (data) {
      for (const obj of state.searchlist) {
        obj.items = obj.items.filter(item =>
          item.name.toLowerCase().includes(data.toLowerCase())
        )
      }
    }
  },
  CLEAR_ORDER(state) {
    state.shopcar = []
    local.remove('shopcar')
  },
  CLEAR_BOOKSHOP(state) {
    state.bookShopcar = []
    local.remove('bookShopcar')
  },
  CLEAR_SHOP(state) {
    state.bookshop = []
    local.remove('booking-shop')
  },
  ISDISPLAY(state, data) {
    state.isdisplay = data
  },

  //axios error
  A_ERROR(state, data) {
    if (!!data.error.reason) {
      state.axios_message = data.error.reason
    } else {
      let error = data.error.validation_error
      for (const prop in error) {
        if (Object.hasOwn(error, prop)) {
          state.axios_message = `${error[prop]}`
        }
      }
    }
  },

  // delivery address
  A_SELECT_ADDRESS(state, data) {
    state.delivery_address = data
    local.set('delivery_address', state.delivery_address)
  },
  A_ADD_ADDRESS(state, data) {
    state.add_address = data
    local.set('add_address', state.add_address)
  },
  A_DELETE_ADDRESS(state) {
    state.delivery_address = {}
    local.remove('delivery_address')
  },
}

//actions
const actions = {
  A_init_token({ commit }, data) {
    commit('INIT_TOKEN', data)
  },
  A_clear_token({ commit }) {
    commit('CLEAR_TOKEN')
  },
  A_userinfo({ commit }, data) {
    commit('USER_INFO', data)
  },
  A_init_goodlist({ commit }, data) {
    commit('INIT_GOODSLIST', data)
  },
  A_init_book_menu({ commit }, data) {
    commit('INIT_BOOK_MENU', data)
  },
  A_details({ commit }, data) {
    commit('GET_DETAILS', data)
  },
  A_changeflag({ commit }, data) {
    commit('CHANGE_FLAG', data)
  },
  A_shopcar({ commit }, data) {
    commit('SHOPCAR', data)
  },
  A_bookShopcar({ commit }, data) {
    commit('BOOKSHOPCAR', data)
  },
  A_bookShop({ commit }, data) {
    commit('BOOKSHOP', data)
  },
  A_clear({ commit }) {
    commit('CLEAR_SHOPCAR')
  },
  A_addshops({ commit }, data) {
    commit('ADDSHOPS', data)
  },
  A_addBookShops({ commit }, data) {
    commit('ADDBOOKSHOPS', data)
  },

  A_subshops({ commit }, data) {
    commit('SUBSHOPS', data)
  },
  A_subBookShops({ commit }, data) {
    commit('SUBBOOKSHOPS', data)
  },

  A_splice({ commit }, data) {
    commit('SPLICE_SHOPCAR', data)
  },
  A_bookSplice({ commit }, data) {
    commit('SPLICE_BOOKSHOPCAR', data)
  },

  A_changeShopCar({ commit }, data) {
    commit('CHANGE_SHOPCAR', data)
  },
  A_changeBookShopCar({ commit }, data) {
    commit('CHANGE_BOOKSHOPCAR', data)
  },

  A_changeShop({ commit }) {
    commit('CHANGE_SHOP')
  },
  A_orderdetails({ commit }, data) {
    commit('ORDER_DETAILS', data)
  },
  A_choose({ commit }, data) {
    commit('CHOOSE', data)
  },
  A_choose1({ commit }, data) {
    commit('CHOOSE1', data)
  },
  A_setdiv({ commit }, data) {
    commit('SETDIV', data)
  },
  A_search({ commit }, data) {
    commit('SEARCH', data)
  },
  A_clearOrder({ commit }) {
    commit('CLEAR_ORDER')
  },
  A_clearBookShop({ commit }) {
    commit('CLEAR_BOOKSHOP')
  },
  A_clearShop({ commit }) {
    commit('CLEAR_SHOP')
  },
  A_is_display({ commit }, data) {
    commit('ISDISPLAY', data)
  },

  //axios error
  A_error({ commit }, data) {
    commit('A_ERROR', data)
  },

  // delivery address
  A_select_address({ commit }, data) {
    commit('A_SELECT_ADDRESS', data)
  },
  A_add_address({ commit }, data) {
    commit('A_ADD_ADDRESS', data)
  },
  A_delete_address({ commit }) {
    commit('A_DELETE_ADDRESS')
  },
}

export default {
  namespaced: true, //namespaced
  state,
  mutations,
  actions,
}
