// huoqu shuju
const getters = {
  token: state => state.app.token,
  goodslist: state => state.app.goodslist,
  book_menu: state => state.app.book_menu,
  searchlist: state => state.app.searchlist,
  details: state => state.app.details,
  shopcar: state => state.app.shopcar,
  bookShopcar: state => state.app.bookShopcar,
  bookshop: state => state.app.bookshop,
  orderdetails: state => state.app.orderdetails,
  choose: state => state.app.choose,
  setdiv: state => state.app.setdiv,
  isdisplay: state => state.app.isdisplay,
  axios_message: state => state.app.axios_message,
  delivery_address: state => state.app.delivery_address,
  add_address: state => state.app.add_address,

  qr: state => state.user.QR,
  web_v3: state => state.user.web_v3,
  homeData: state => state.user.homeData,
}

export default getters
