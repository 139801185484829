import local from '@/utils/local'
import theme from '@/utils/theme'
import { CafeqrReq, CafeReq } from '@/api/goods.js'

const state = {
  QR: local.get('QR'),
  web_v3: local.get('web_v3'),
  title: local.get('title'),
  homeData : {}
}

//mutations
const mutations = {
  GET_QR(state, data) {
    state.QR = data
    local.set('QR', data)
  },
  GET_V3(state, data) {
    state.web_v3 = data
  },
  GET_TITLE(state, data) {
    state.title = data
  },

  HOME_DATA(state, data) {
    state.homeData = data
  },
}

//actions
const actions = {
  A_get_qr({ commit }) {
    if (process.client) {
      let qr = window.location.hostname
      return new Promise(async resolve => {
        let cafeqr = {}
        let res = await CafeqrReq({ qr })
        let { cafe_id, pub_access_token } = res.data
        cafeqr.cafe_id = cafe_id
        cafeqr.pub_access_token = pub_access_token
        local.set('cafeqr', cafeqr)
        let res1 = await CafeReq(cafeqr)
        if (res1) {
          commit('GET_QR', res1.data.cafe_config.web_take_away)
          commit('GET_V3', res1.data.cafe_config.adv_web_theme)
          local.set('cafe_config', res1.data)
          local.set('web_v3', res1.data.cafe_config.adv_web_theme)
          local.set('web_theme', res1.data.cafe_config.web_theme)
          let {
            web_dine_in,
            web_take_away,
            booking,
            adv_booking,
            web_delivery,
            loyalty_credit,
            enable_door_dash_tip,
          } = res1.data.cafe_config
          local.set('web_dine_in', web_dine_in)
          local.set('web_take_away', web_take_away)
          local.set('web_delivery', web_delivery)
          local.set('bookingFlag', booking)
          local.set('advBookingFlag', adv_booking)
          local.set('enable_door_dash_tip', enable_door_dash_tip)
          local.set('cafe_name', res1.data.cafename)
          commit('GET_TITLE', res1.data.cafename)
          local.set('venueName', res1.data.venue_name)
          local.set('announcements', res1.data.storeannouncements)
          local.set('holidays', res1.data.holidays)
          local.set('specialHours', res1.data.special_hours)
          local.set('img', res1.data.logo)
          local.set('phone_number', res1.data.phone_number)
          local.set('Email', res1.data.email)
          local.set('credits_rate', res1.data.credits_rate)
          local.set('loyalty_credit', loyalty_credit)
          local.set('address', res1.data.address)
          local.set('country', res1.data.country)
          local.set('voucher_expire_days', res1.data.voucher_expire_days)
          local.set('currency', res1.data.currency)
          local.set('isAdvLogin', false)

          local.set('facebook', res1.data.facebook)
          local.set('instagram', res1.data.instagram)
          local.set('open_hours', res1.data.open_hours)
          local.set('pickup_hours', res1.data.pickup_hours)
          local.set('tables', res1.data.tables)
          local.set('stripe_pub_key', res1.data.stripe_pub_key)
          local.set('google_map_key', res1.data.google_map_key)

          local.set('stripeid', res1.data.stripeid)
          local.set('cafe_id', cafe_id)
          local.set('pub_access_token', pub_access_token)
          local.set(
            'pre_order_max_days',
            res1.data.cafe_config.app_order_config.pre_order_max_days
          )
          local.set(
            'pickup_before_hand_mins',
            res1.data.cafe_config.app_order_config.pickup_before_hand_mins
          )
          local.set(
            'pickup_time_interval_mins',
            res1.data.cafe_config.app_order_config.pickup_time_interval_mins
          )
          local.set('time_zone', res1.data.time_zone)

          // advbooking
          local.set('industry_quick_notes', res1.data.industry_quick_notes)
          local.set('booking_time_slots', res1.data.booking_time_slots)
          local.set('booking_config', res1.data.booking_config)
          local.set('booking_promos', res1.data.booking_promos)
          local.set('business_type', res1.data.business_type)
          let name_list = res1.data.venues.filter(item => item.enabled)
          local.set('booking_venues', name_list)

          let open_animation_background_color =
            res1.data.cafe_config.web_theme?.open_animation_background_color ||
            '#a99153'
          let color =
            res1.data.cafe_config.web_theme?.primary_color || '#000000'
          let color1 =
            res1.data.cafe_config.web_theme?.secondary_color || '#FFF8F8'
          let color2 =
            res1.data.cafe_config.web_theme?.tertiary_color || '#1E092C'

          let on_color =
            res1.data.cafe_config.web_theme?.text_on_primary_color || '#FFFFFF'
          let on_color1 =
            res1.data.cafe_config.web_theme?.text_on_secondary_color ||
            '#000000'
          let on_color2 =
            res1.data.cafe_config.web_theme?.text_on_tertiary_color || '#000000'

          let nav_background_color =
            res1.data.cafe_config.web_theme?.nav_background_color || '#000000'
          let nav_foreground_color =
            res1.data.cafe_config.web_theme?.nav_foreground_color || '#FFFFFF'
          let nav_selected_color =
            res1.data.cafe_config.web_theme?.nav_selected_color || '#000000'

          let qr_background_color =
            res1.data.cafe_config.web_theme?.qr_background_color || '#F2F2F7'
          let search_box_on_banner_color =
            res1.data.cafe_config.web_theme?.search_box_on_banner_color ||
            '#FFFFFF'

          let container_background_color =
            res1.data.cafe_config.web_theme?.container_background_color ||
            '#F8F8F8'
          let container_foreground_color =
            res1.data.cafe_config.web_theme?.container_foreground_color ||
            '#3C3D3C'
          let action_on_container_color =
            res1.data.cafe_config.web_theme?.action_on_container_color ||
            '#7AC2C2'

          let main_font =
            res1.data.cafe_config.web_theme?.main_font || 'Georgia'
          let secondary_font =
            res1.data.cafe_config.web_theme?.secondary_font || 'SF Pro Display'

          document.documentElement.style.setProperty('--main_font', main_font)
          document.documentElement.style.setProperty(
            '--secondary_font',
            secondary_font
          )
          document.documentElement.style.setProperty(
            '--open_animation_background_color',
            open_animation_background_color
          )
          document.documentElement.style.setProperty('--color', color)
          document.documentElement.style.setProperty('--color1', color1)
          document.documentElement.style.setProperty('--color2', color2)
          document.documentElement.style.setProperty('--on_color', on_color)
          document.documentElement.style.setProperty('--on_color1', on_color1)
          document.documentElement.style.setProperty('--on_color2', on_color2)

          document.documentElement.style.setProperty(
            '--nav_background_color',
            nav_background_color
          )
          document.documentElement.style.setProperty(
            '--nav_foreground_color',
            nav_foreground_color
          )
          document.documentElement.style.setProperty(
            '--nav_selected_color',
            nav_selected_color
          )

          document.documentElement.style.setProperty(
            '--qr_background_color',
            qr_background_color
          )

          document.documentElement.style.setProperty(
            '--search_box_on_banner_color',
            search_box_on_banner_color
          )

          document.documentElement.style.setProperty(
            '--container_background_color',
            container_background_color
          )
          document.documentElement.style.setProperty(
            '--container_foreground_color',
            container_foreground_color
          )
          document.documentElement.style.setProperty(
            '--action_on_container_color',
            action_on_container_color
          )

          // kh theme
          theme(res1.data.cafe_config.web_theme.web_theme_token.data)
          resolve()
        }
      })
    }
  },

  A_home_data({ commit }, data) {
    commit('HOME_DATA', data)
  },
}

export default {
  //空间命名
  namespaced: true,
  state,
  mutations,
  actions,
}
