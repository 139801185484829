// local
const local = {
  /** sessionStorage **/
  // set
  set(key, val) {
    if (process.client) {
      sessionStorage.setItem(key, JSON.stringify(val))
    }
  },
  // get
  get(key) {
    if (process.client) {
      return JSON.parse(sessionStorage.getItem(key))
    }
  },
  // remove
  remove(key) {
    sessionStorage.removeItem(key)
  },
  // clear
  clear() {
    sessionStorage.clear()
  },

  /** localStorage **/

  setLocal(key, val) {
    if (process.client) {
      localStorage.setItem(key, JSON.stringify(val))
    }
  },

  getLocal(key) {
    if (process.client) {
      return JSON.parse(localStorage.getItem(key))
    }
  },

  removeLocal(key) {
    localStorage.removeItem(key)
  },

  //cookie
  setCookie(key, value, iDay) {
    var oDate = new Date()
    oDate.setDate(oDate.getDate() + iDay)
    document.cookie = key + '=' + value + ';expires=' + oDate + ';path=/'
  },
  removeCookie(key) {
    setCookie(key, '', -1)
  },
  delCookie() {
    var cookies = document.cookie.split(';')
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i]
      var eqPos = cookie.indexOf('=')
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/'
    }
    if (cookies.length > 0) {
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i]
        var eqPos = cookie.indexOf('=')
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        var domain = location.host.substr(location.host.indexOf('.'))
        document.cookie =
          name +
          '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=' +
          domain
      }
    }
  },
  getCookie(key) {
    var cookieArr = document.cookie.split('; ')
    for (var i = 0; i < cookieArr.length; i++) {
      var arr = cookieArr[i].split('=')
      if (arr[0] === key) {
        return arr[1]
      }
    }
    return false
  },
}

export default local
