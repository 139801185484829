import store from '@/store'

//  sentry
import * as Sentry from '@sentry/vue'
// import { BrowserTracing } from '@sentry/browser'

//Firebase
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'


export default defineNuxtPlugin(nuxtApp => {
  //Sentry init
  const app = nuxtApp.vueApp
  const isProd = import.meta.env.PROD
  //Firebase init
  if (process.client) {
    const firebaseConfig = {
      apiKey: 'AIzaSyC5NBmcU7TrtwuTghKoFTjH3Yg7q00UweY',
      authDomain: 'clientweb-ab4cb.firebaseapp.com',
      projectId: 'clientweb-ab4cb',
      storageBucket: 'clientweb-ab4cb.appspot.com',
      messagingSenderId: '664117451194',
      appId: isProd ? '1:664117451194:web:a8395440386c15855a6583' : '1:664117451194:web:dfdc05ac8d3418d15a6583',
      measurementId: 'G-XPJPBPY65P',
    }
    const firebaseApp = initializeApp(firebaseConfig)
    const analytics = getAnalytics(firebaseApp)
  }

  /** bu shu hou css unable to preload  **/
  if (process.client) {
    window.addEventListener(
      'error',
      error => {
        if (!/SyntaxError/gi.test(error?.message) || !window.navigator.onLine) return
          window.location.reload()
      },
      true
    )
  }

  app.use(store)
})
