import { default as addAddressXuxhKycGyVMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/addAddress.vue?macro=true";
import { default as advancedbookingFXJEqqPq55Meta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/advancedbooking.vue?macro=true";
import { default as advbookingcartaTfEPBw6GsMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/advbookingcart.vue?macro=true";
import { default as advbookingconf3LxButuwjtMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/advbookingconf.vue?macro=true";
import { default as advbookingmenuby5hkE8akkMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/advbookingmenu.vue?macro=true";
import { default as advbookingreqpkBW0eEAQcMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/advbookingreq.vue?macro=true";
import { default as advbookingsuczJNOzF3Gq7Meta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/advbookingsuc.vue?macro=true";
import { default as chooseconfirm1tQcKlNIQyMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/chooseconfirm.vue?macro=true";
import { default as choosetableoaWwUvNy5pMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/choosetable.vue?macro=true";
import { default as confirmq3Lb9oO7ciMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/confirm.vue?macro=true";
import { default as deliveryAddressVS4en9ddqYMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/deliveryAddress.vue?macro=true";
import { default as editAddress4OHUSUQQIzMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/editAddress.vue?macro=true";
import { default as historybookingb08DglValfMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/historybooking.vue?macro=true";
import { default as historyorder8vIaWZdIRUMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/historyorder.vue?macro=true";
import { default as index5bk9xlX8HgMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/index.vue?macro=true";
import { default as mypage6ge3a9Ca48Meta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/mypage.vue?macro=true";
import { default as mypagebookdetailsL5w0o8oN9VMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/mypagebookdetails.vue?macro=true";
import { default as aboutusz8ceHriHboMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/aboutus.vue?macro=true";
import { default as bookcartCKeaOdVm7NMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/book/bookcart.vue?macro=true";
import { default as bookconfhCphXckcvRMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/book/bookconf.vue?macro=true";
import { default as bookinfofVUDI4Dg8gMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/book/bookinfo.vue?macro=true";
import { default as bookmenuyRGLCn54PiMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/book/bookmenu.vue?macro=true";
import { default as bookreq0KASu6uOXWMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/book/bookreq.vue?macro=true";
import { default as booksucrhyGgP9xmBMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/book/booksuc.vue?macro=true";
import { default as bookiwNg6iCcBsMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/book.vue?macro=true";
import { default as bookingconfFatztuBivlMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booking/bookingconf.vue?macro=true";
import { default as bookingdate88x8rzCRjzMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booking/bookingdate.vue?macro=true";
import { default as bookinginfomrbpveyyyTMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booking/bookinginfo.vue?macro=true";
import { default as bookingmenuHGwEuuBLUcMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booking/bookingmenu.vue?macro=true";
import { default as bookingreqBOOKBerRjeMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booking/bookingreq.vue?macro=true";
import { default as bookingsucw8ohjb3C3YMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booking/bookingsuc.vue?macro=true";
import { default as bookingoA5ImrpztqMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booking.vue?macro=true";
import { default as booknowMU2jNp4GMcMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booknow.vue?macro=true";
import { default as galleryXZAOccrrnLMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/gallery.vue?macro=true";
import { default as homeUm2LmFRAs0Meta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/home.vue?macro=true";
import { default as menuLV4yUheoLAMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/menu.vue?macro=true";
import { default as service6OrvZMarwNMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/service.vue?macro=true";
import { default as voucherQVsslb3qVeMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/voucher.vue?macro=true";
import { default as vouchernow1iMVQ34v7RMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/vouchernow.vue?macro=true";
import { default as voucherPreviewXYOaPdKYR3Meta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/voucherPreview.vue?macro=true";
import { default as navnBvPp2F4XgMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav.vue?macro=true";
import { default as orderAddressXI1sHSKG6HMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/orderAddress.vue?macro=true";
import { default as paymentz73BmHHlocMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/payment.vue?macro=true";
import { default as paymentmethodsqhHUjVcwVGMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/paymentmethods.vue?macro=true";
import { default as paymentorderavYT4rZxGvMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/paymentorder.vue?macro=true";
import { default as popup3tTDKKQ5AkMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/popup.vue?macro=true";
import { default as preferenceoXAcy7ZCHaMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/preference.vue?macro=true";
import { default as productfY03S6evGeMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/product.vue?macro=true";
import { default as sitemapUit9r3Fk9AMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/sitemap.vue?macro=true";
import { default as transferM4wrbgw2wLMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/transfer.vue?macro=true";
import { default as userinfoDHZgk699XxMeta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/userinfo.vue?macro=true";
import { default as voucherconfQbcf6EFh79Meta } from "/codebuild/output/src3237485789/src/ClientWebNuxt/pages/voucherconf.vue?macro=true";
export default [
  {
    name: "addAddress",
    path: "/addAddress",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/addAddress.vue")
  },
  {
    name: "advancedbooking",
    path: "/advancedbooking",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/advancedbooking.vue")
  },
  {
    name: "advbookingcart",
    path: "/advbookingcart",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/advbookingcart.vue")
  },
  {
    name: "advbookingconf",
    path: "/advbookingconf",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/advbookingconf.vue")
  },
  {
    name: "advbookingmenu",
    path: "/advbookingmenu",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/advbookingmenu.vue")
  },
  {
    name: "advbookingreq",
    path: "/advbookingreq",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/advbookingreq.vue")
  },
  {
    name: "advbookingsuc",
    path: "/advbookingsuc",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/advbookingsuc.vue")
  },
  {
    name: "chooseconfirm",
    path: "/chooseconfirm",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/chooseconfirm.vue")
  },
  {
    name: "choosetable",
    path: "/choosetable",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/choosetable.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/confirm.vue")
  },
  {
    name: "deliveryAddress",
    path: "/deliveryAddress",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/deliveryAddress.vue")
  },
  {
    name: "editAddress",
    path: "/editAddress",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/editAddress.vue")
  },
  {
    name: "historybooking",
    path: "/historybooking",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/historybooking.vue")
  },
  {
    name: "historyorder",
    path: "/historyorder",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/historyorder.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/index.vue")
  },
  {
    name: "mypage",
    path: "/mypage",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/mypage.vue")
  },
  {
    name: "mypagebookdetails",
    path: "/mypagebookdetails",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/mypagebookdetails.vue")
  },
  {
    name: "nav",
    path: "/nav",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav.vue"),
    children: [
  {
    name: "nav-aboutus",
    path: "aboutus",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/aboutus.vue")
  },
  {
    name: "nav-book",
    path: "book",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/book.vue"),
    children: [
  {
    name: "nav-book-bookcart",
    path: "bookcart",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/book/bookcart.vue")
  },
  {
    name: "nav-book-bookconf",
    path: "bookconf",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/book/bookconf.vue")
  },
  {
    name: "nav-book-bookinfo",
    path: "bookinfo",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/book/bookinfo.vue")
  },
  {
    name: "nav-book-bookmenu",
    path: "bookmenu",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/book/bookmenu.vue")
  },
  {
    name: "nav-book-bookreq",
    path: "bookreq",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/book/bookreq.vue")
  },
  {
    name: "nav-book-booksuc",
    path: "booksuc",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/book/booksuc.vue")
  }
]
  },
  {
    name: "nav-booking",
    path: "booking",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booking.vue"),
    children: [
  {
    name: "nav-booking-bookingconf",
    path: "bookingconf",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booking/bookingconf.vue")
  },
  {
    name: "nav-booking-bookingdate",
    path: "bookingdate",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booking/bookingdate.vue")
  },
  {
    name: "nav-booking-bookinginfo",
    path: "bookinginfo",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booking/bookinginfo.vue")
  },
  {
    name: "nav-booking-bookingmenu",
    path: "bookingmenu",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booking/bookingmenu.vue")
  },
  {
    name: "nav-booking-bookingreq",
    path: "bookingreq",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booking/bookingreq.vue")
  },
  {
    name: "nav-booking-bookingsuc",
    path: "bookingsuc",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booking/bookingsuc.vue")
  }
]
  },
  {
    name: "nav-booknow",
    path: "booknow",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/booknow.vue")
  },
  {
    name: "nav-gallery",
    path: "gallery",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/gallery.vue")
  },
  {
    name: "nav-home",
    path: "home",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/home.vue")
  },
  {
    name: "nav-menu",
    path: "menu",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/menu.vue")
  },
  {
    name: "nav-service",
    path: "service",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/service.vue")
  },
  {
    name: "nav-voucher",
    path: "voucher",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/voucher.vue")
  },
  {
    name: "nav-vouchernow",
    path: "vouchernow",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/vouchernow.vue")
  },
  {
    name: "nav-voucherPreview",
    path: "voucherPreview",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/nav/voucherPreview.vue")
  }
]
  },
  {
    name: "orderAddress",
    path: "/orderAddress",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/orderAddress.vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/payment.vue")
  },
  {
    name: "paymentmethods",
    path: "/paymentmethods",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/paymentmethods.vue")
  },
  {
    name: "paymentorder",
    path: "/paymentorder",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/paymentorder.vue")
  },
  {
    name: "popup",
    path: "/popup",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/popup.vue")
  },
  {
    name: "preference",
    path: "/preference",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/preference.vue")
  },
  {
    name: "product",
    path: "/product",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/product.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/sitemap.vue")
  },
  {
    name: "transfer",
    path: "/transfer",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/transfer.vue")
  },
  {
    name: "userinfo",
    path: "/userinfo",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/userinfo.vue")
  },
  {
    name: "voucherconf",
    path: "/voucherconf",
    component: () => import("/codebuild/output/src3237485789/src/ClientWebNuxt/pages/voucherconf.vue")
  }
]